export const dataset = [
    { subscriptions: 100, month: 'Jan' },
    { subscriptions: 150, month: 'Feb' },
    { subscriptions: 200, month: 'Mar' },
    { subscriptions: 175, month: 'Apr' },
    { subscriptions: 220, month: 'May' },
    { subscriptions: 300, month: 'Jun' },
    { subscriptions: 280, month: 'Jul' },
    { subscriptions: 350, month: 'Aug' },
    { subscriptions: 240, month: 'Sep' },
    { subscriptions: 210, month: 'Oct' },
    { subscriptions: 180, month: 'Nov' },
    { subscriptions: 160, month: 'Dec' },
  ];
  